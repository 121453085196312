import React from 'react'
import catA from '../../assets/footer/catA.svg';
import catB from '../../assets/footer/catB.svg';
import catC1 from '../../assets/footer/catC1.svg';
import catC from '../../assets/footer/catC.svg';
import catD from '../../assets/footer/catD.svg';


const Footer = () => {
    return (
        <div className='hidden sm:flex container mx-auto absolute bottom-0 left-1/2 transform -translate-x-1/2 space-x-[200px]'>
            <div>
                <img src={catA} alt="..." />
            </div>
            <div>
                <img src={catB} alt="..." />
            </div>
            <div>
                <img src={catC1} alt="..." />
            </div>
            <div>
                <img src={catC} alt="..." />
            </div>
            <div>
                <img src={catD} alt="..." />
            </div>
        </div>
    )
}

export default Footer