import React from 'react'
import qrcode from '../../assets/qrcode.svg'
import A from '../../assets/A.svg';
import B from '../../assets/B.svg';
import C1 from '../../assets/C1.svg';
import C from '../../assets/C.svg';
import D from '../../assets/D.svg';

const MainPage = () => {
    return (
        <div className=' flex justify-between items-center p-4  container mx-auto sm:flex-col sm:justify-center'>
            <h1 className='hidden sm:inline text-white text-shadow-md font-archivo-black text-7xl font-normal'>Autoshkollaime</h1>
            <div className='sm:hidden'>
                <h3 className="flex items-center text-white font-poppins text-2xl font-extrabold">Kategorit:</h3>
                <ul className="list-none p-0 w-[120px]">
                    <li  className="flex items-center justify-between">
                        <p>A</p>
                        <img src={A} alt='icon'/>
                    </li>
                    <li  className="flex items-center justify-between ">
                        <p>B</p>
                        <img src={B} alt='icon'/>
                    </li>
                    <li   className="flex items-center justify-between">
                        <p>C1</p>
                        <img src={C1} alt='icon'/>
                    </li>
                    <li   className="flex items-center justify-between">
                        <p>C</p>
                        <img src={C} alt='icon'/>
                    </li>
                    <li   className="flex items-center justify-between">
                        <p>D</p>
                        <img src={D} alt='icon'/>
                    </li>
                </ul>
            </div>
            <div className='mt-5'>
                <img src={qrcode} alt="QRcode" />
            </div>
        </div>
    )
}

export default MainPage