import React from 'react'
import failed from '../../assets/icon/failed.svg'
const ErrorPage = () => {
  return (
    <div className='flex flex-col items-center justify-center align-center mt-[80px] space-y-[200px]'>
      <h1 className=' w-[80%] text-center font-poppins text-xl font-semibold leading-tight tracking-tighter text-gray-800'>
        Pagesa ka dështuar. Ju lutemi, provoni përsëri!
      </h1>
      <div className='mt-4'>
        <img src={failed} alt="icon" />
      </div>
      <p className="w-[80%] text-center font-poppins text-base font-normal leading-9 tracking-tight text-gray-800 ">
        Për çdo problem të mundshëm, mund të na kontaktoni në mbështetjen teknike, e cila gjendet tek profil.
      </p>
    </div>
  )
}

export default ErrorPage