import React from 'react'
import success from '../../assets/icon/success.svg'

const ThankPage = () => {
  return (
    <div className='flex flex-col items-center justify-center align-center mt-[80px] space-y-[200px]'>
      <h1 className=' w-[70%] text-center font-poppins text-xl font-semibold leading-tight tracking-tighter text-gray-800'>Abonimi është aktivizuar me sukses.</h1>
      <div className='mt-4'>
        <img src={success} alt="icon" />
      </div>
      <p className="w-[70%] text-center font-poppins text-base font-normal leading-9 tracking-tight text-gray-800">
        Faleminderit që zgjodhët Autoshkollaime.
      </p>
    </div>
  )
}

export default ThankPage