import React from 'react'
import card1 from '../../assets/card1.svg';
import card2 from '../../assets/card2.svg';

const Card = () => {
  return (
    <div className='flex flex-col sm:flex-row sm:justify-between container mx-auto sm:w-[75%] items-center'>
        <div>
            <img src={card1} alt='card' className='sm:mt-[-100px]'/>
        </div>
        <div>
        <img src={card2} alt='card' className='sm:mt-[50px]' />
        </div>
    </div>
  )
}

export default Card