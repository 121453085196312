import React from 'react';
import { Button, Header, MainPage, Card, Footer } from '../../components';
import phone from '../../assets/phone.svg';

const Home = () => {
  return (
    <div className="flex flex-col  bg-[#FFC500] min-h-[180vh] sm:min-h-[100vh]">
      <div className="flex-grow">
        <Header />
        <MainPage />
        <Button />
        <Card />
        <Footer />
      </div>
      <div className="flex items-center justify-center  fixed bottom-[-370px] left-0 right-0">
        <img src={phone} alt='phone photo' />
      </div>
    </div>
  );
}

export default Home;
