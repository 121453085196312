import React from 'react'

const Info = () => {
    return (
        <div>
            <p><strong>Privacy Policy for Autoshkollaime</strong></p>
            <p><strong>Introduction</strong><br />
                Welcome to Autoshkollaime! Our app is dedicated to providing an educational platform for learning driving school theory. We are committed to protecting the privacy and security of all our users, including younger learners. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our app.</p>
            <p><strong>Information Collection and Use</strong><br />
                <ul>
                    <li><strong>Personal Information:</strong> We collect personal information that you voluntarily provide to us when registering for the app, such as your name, email address, and contact details.</li>
                    <li><strong>Usage Data:</strong> We collect information on how the app is accessed and used. This may include your device's Internet Protocol address, browser type, version, the pages of our app that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data.</li>
                    <li><strong>Cookies and Tracking Data:</strong> We use cookies and similar tracking technologies to track activity on our app and store certain information.</li>
                </ul>
            </p>
            <p><strong>Data Use</strong><br />
                <ul>
                    <li>To provide and maintain our Service</li>
                    <li>To notify you about changes to our Service</li>
                    <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                    <li>To provide customer support</li>
                    <li>To gather analysis or valuable information so that we can improve our Service</li>
                    <li>To monitor the usage of our Service</li>
                    <li>To detect, prevent and address technical issues</li>
                </ul>
            </p>
            <p><strong>Data Sharing and Disclosure</strong><br />
                We may share your personal information in the following situations:
                <ul>
                    <li><strong>With Service Providers:</strong> We may share your personal information with service providers to monitor and analyze the use of our service, to contact you.</li>
                    <li><strong>For Business Transfers:</strong> We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                    <li><strong>With Affiliates:</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy policy.</li>
                    <li><strong>With Law enforcement:</strong> Under certain circumstances, we may be required to disclose your personal information if required to do so by law or in response to valid requests by public authorities.</li>
                    <li><strong>For other legal requirements:</strong> We may disclose your personal information in the good faith belief that such action is necessary to comply with a legal obligation, protect and defend the rights or property of Autoshkollaime, prevent or investigate possible wrongdoing in connection with the Service, protect the personal safety of users of the Service or the public, and protect against legal liability.</li>
                </ul>
            </p>
            <p><strong>Data Security</strong><br />
                We value your trust in providing us your personal information, thus we are striving to use commercially acceptable means of protecting it. However, no method of transmission over the internet or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
            <p><strong>Children's Privacy</strong><br />
                Our Service is designed to be suitable for users under the age of 18. We take extra care to protect the privacy of our younger users. We do not collect more personal information than is reasonably necessary to participate in the educational activities offered on the app. If we discover that we have collected personal information from a child without verification of parental consent, we take steps to remove that information from our servers.</p>
            <p><strong>Changes to This Privacy Policy</strong><br />
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
            <p><strong>Contact Us</strong><br />
                If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
        </div>
    )
}

export default Info