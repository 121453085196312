import React from 'react';
import logo from '../../assets/logo.svg';

const Header = () => {
  return (
    <header className="p-4">
      <div className="container mx-auto ">
        <div className="flex justify-between items-center sm:justify-start">
          <img src={logo} alt="Logo" className="h-10" />

          <div className="sm:flex sm:place-content-center">
            <h1 className='text-white text-shadow font-archivo-black text-3xl font-normal sm:hidden'>Autoshkollaime</h1>
            <ul className='list-none p-0 sm:space-x-[100px] lg:space-x-[300px]'>
              <li className='inline-block mx-2 text-white text-shadow sm:text-2xl lg:text-4xl'>Testet</li>
              <li className='inline-block mx-2 text-white text-shadow sm:text-2xl lg:text-4xl'>Pyetje</li>
              <li className='inline-block mx-2 text-white text-shadow sm:text-2xl lg:text-4xl'>Literatur</li>
              <li className='hidden sm:inline-block  mx-2 text-white text-shadow lg:text-4xl'>Kategoritë:</li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
