import React from 'react'
import appstore from '../../assets/appstore.svg';
import playstore from '../../assets/playstore.svg';

const Button = () => {
  return (
    <div className=' flex justify-center items-center p-4 space-x-4 container mx-auto'>
        <div>
            <img src={appstore} alt="button"/>
        </div>
        <div>
            <img src={playstore} alt='button'/>
        </div>
    </div>
  )
}

export default Button