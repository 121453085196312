import './App.css';
import { ErrorPage, Home, ThankPage, Info } from './pages'
import { Routes, Route } from "react-router-dom";
function App() {
  return (
    <Routes>
        <Route path="/"  element={<Home />} />
        <Route path='/thankyou' element={<ThankPage />}/>
        <Route path='/failed' element={<ErrorPage />}/>
        <Route path='/privacy' element={<Info />}/>
    </Routes>
  );
}

export default App;
